import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import BizFeaturesMarquee from '../../components/BizFeaturesMarquee';
import HomeImageList from '../../components/List/HomeImageList';
import Customers from '../../modules/home/HomeCustomers';
import TrialStart from '../../modules/home/TrialStart';
import { PARTNERS_AND_CERTIFICATIONS_LIST } from '../../config';
import { SCREEN_PAD } from '../../shared/styles';

const PageLayout = ({ blockPadding, className, children }) => {
  return (
    <>
      <PageContainer className={className} blockPadding={blockPadding}>
        {children}
      </PageContainer>
      <Customers /> <BizFeaturesMarquee />
      <TrialStart />
      <HomeImageList
        title="home.partner.title"
        ImageList={PARTNERS_AND_CERTIFICATIONS_LIST}
      />
    </>
  );
};

const PageContainer = styled.div`
  display: flex;
  flex-flow: column;
  ${(props) => !props.blockPadding && SCREEN_PAD};
  padding-bottom: 3rem;
  padding-top: 3rem;
`;

PageLayout.propTypes = {
  blockPadding: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default PageLayout;
