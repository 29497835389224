/* eslint-disable import/no-unused-modules */
import React from 'react';
import { graphql } from 'gatsby';
import Helmet from '../components/Helmet';
import { PolicyLayout } from '../shared/layouts';
import withI18n from '../hocs/withI18n';

export const Head = withI18n(() => {
  return <Helmet pageName="privacyPolicy" />;
});

const PrivacyPolicy = () => (
  <PolicyLayout
    title="privacy-policy.title"
    intro="privacy-policy.introduction"
    content={privacyPolicyContent}
    isLastUpdated
  />
);

const privacyPolicyContent = [
  {
    title: 'privacy-policy.laws.title',
    content: 'privacy-policy.laws.content',
  },
  {
    title: 'privacy-policy.information-optional.title',
    content: 'privacy-policy.information-optional.content',
  },
  {
    title: 'privacy-policy.information-user.title',
    content: 'privacy-policy.information-user.content',
  },
  {
    title: 'privacy-policy.information-collect.title',
    content: 'privacy-policy.information-collect.content',
  },
  {
    title: 'privacy-policy.information-sources.title',
    content: 'privacy-policy.information-sources.content',
  },
  {
    title: 'privacy-policy.information-personal.title',
    content: 'privacy-policy.information-personal.content',
  },
  {
    title: 'privacy-policy.information-legal.title',
    content: 'privacy-policy.information-legal.content',
  },
  {
    title: 'privacy-policy.information-timeline.title',
    content: 'privacy-policy.information-timeline.content',
    email: {
      textBefore: 'privacy-policy.information-timeline.email_start',
      emailID: 'info@novalabs-qa.com',
    },
  },
  {
    title: 'privacy-policy.rights.title',
    content: 'privacy-policy.rights.content',
  },
  {
    title: 'privacy-policy.data-store.title',
    content: 'privacy-policy.data-store.content',
  },
  {
    title: 'privacy-policy.data-transfer.title',
    content: 'privacy-policy.data-transfer.content',
  },
  {
    title: 'privacy-policy.data-protection.title',
    content: 'privacy-policy.data-protection.content',
    email: {
      textBefore: 'privacy-policy.email',
      emailID: 'info@novalabs-qa.com',
    },
  },
  {
    title: 'privacy-policy.other-websites.title',
    content: 'privacy-policy.other-websites.content',
  },
  {
    title: 'privacy-policy.children.title',
    content: 'privacy-policy.children.content',
  },
  {
    title: 'privacy-policy.changes.title',
    content: 'privacy-policy.changes.content',
  },
];

export default PrivacyPolicy;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
