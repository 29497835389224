import peopleImg from '../../../static/svgs/contact-us/reports.svg';
import appstoreImg from '../../../static/svgs/featuresBuiltForYou/file-management.svg';
import languageImg from '../../../static/svgs/featuresBuiltForYou/multi-language.svg';
import customizationImg from '../../../static/svgs/featuresBuiltForYou/style-customizations.svg';

export const contactUsFeaturesList = [
  {
    icon: peopleImg,
    iconColor: 'rgba(255, 226, 82, .08)',
    title: 'get_in_touch.feature_1_title',
    description: 'get_in_touch.feature_1_desc',
  },
  {
    icon: customizationImg,
    iconColor: 'rgba(255, 226, 82, .08)',
    title: 'get_in_touch.feature_2_title',
    description: 'get_in_touch.feature_2_desc',
  },
  {
    icon: appstoreImg,
    iconColor: 'rgba(255, 168, 157, .08)',
    title: 'get_in_touch.feature_3_title',
    description: 'get_in_touch.feature_3_desc',
  },
  {
    icon: languageImg,
    iconColor: 'rgba(127, 222, 203, .08)',
    title: 'get_in_touch.feature_4_title',
    description: 'get_in_touch.feature_4_desc',
  },
];
