import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Button } from '../../components/Button';
import { H2, P, PMedium } from '../../components/Text';
import PageLayout from './PageLayout';
import { onDownloadPDF } from '../service';
import { contactUsFeaturesList } from '../../modules/get-in-touch/GetInTouch.helper';
import { PDF_BROCHURE_DOWNLOAD_LINK } from '../../config';
import { FONT_BOLD, screenLessThan } from '../../shared/styles';
import downloadImg from '../../../static/svgs/common/download-white.svg';

const ContactLayout = ({ children }) => {
  const { t } = useTranslation();

  const onButtonClick = () =>
    onDownloadPDF(PDF_BROCHURE_DOWNLOAD_LINK, 'Biz_Brochure.pdf');

  const ContactFeatures = (
    <>
      <ContactLayoutDivider />
      <PBold margin="0 0 1.5rem 0">
        {t('get_in_touch.biz_exclusive_features')}
      </PBold>
      {contactUsFeaturesList.map((feature) => (
        <ContactFeatureCard key={feature.title}>
          <ContactFeatureIcon
            color={feature.iconColor}
            src={feature.icon}
            alt="feature"
          />
          <P align="start" margin="0">
            <strong>{t(feature.title)}</strong> - {t(feature.description)}
          </P>
        </ContactFeatureCard>
      ))}
    </>
  );

  return (
    <PageLayoutWrapper>
      <ContactLayoutSection>
        <H2 align="start" margin="0 0 1.5rem 0">
          {t('get_in_touch.title')}
        </H2>
        <PMedium margin="0 0 1rem 0">{t('get_in_touch.subtitle')}</PMedium>
        <Button onClick={onButtonClick}>
          {t('get_in_touch.download_pdf')}
          <DownloadIcon src={downloadImg} alt="download" />
        </Button>

        {/* Shows only for large devices */}
        <ContactMDWrap>{ContactFeatures}</ContactMDWrap>
      </ContactLayoutSection>

      <ContactLayoutSection>{children}</ContactLayoutSection>

      {/* Shows only for smaller devices */}
      <ContactSMWrap>{ContactFeatures}</ContactSMWrap>
    </PageLayoutWrapper>
  );
};

const PageLayoutWrapper = styled(PageLayout)`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  ${screenLessThan('1000px')} {
    flex-direction: column;
  }
`;

const ContactMDWrap = styled.div`
  display: flex;
  flex-direction: column;
  ${screenLessThan('1000px')} {
    display: none;
  }
`;
const ContactSMWrap = styled.div`
  display: none;
  ${screenLessThan('1000px')} {
    display: flex;
    flex-direction: column;
    width: 95%;
  }
`;
const DownloadIcon = styled.img`
  margin-left: 5px;
`;
const ContactLayoutSection = styled.div`
  width: 45%;
  ${screenLessThan('1000px')} {
    margin-bottom: 2rem;
    width: 90%;
  }
`;
const ContactFeatureCard = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 2.3rem;
  p {
    line-height: 1.7;
  }
`;
const ContactFeatureIcon = styled.img`
  background-color: ${(props) => props.color};
  border-radius: 8px;
  margin-right: 1.2rem;
  padding: 1rem;
  width: 45px;
`;
const ContactLayoutDivider = styled.hr`
  border: none;
  border-top: 1px solid #eae8e8;
  margin: 2rem 1rem 2rem 0;
  width: 100%;
`;

const PBold = styled(PMedium)`
  ${FONT_BOLD}
`;

ContactLayout.propTypes = {
  children: PropTypes.node,
};

export default ContactLayout;
