import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '../../components/Accordion';
import { H1, P } from '../../components/Text';
import TransHTML from '../../components/TransHTML';
import PageLayout from './PageLayout';
import { PRIVACY_POLICY_LAST_UPDATED_DATE } from '../../config';
import { PRIMARY_COLOR } from '../styles';

const PolicyLayout = ({ title, intro, content, isLastUpdated = false }) => {
  const { t } = useTranslation();
  return (
    <PageLayout>
      <span className="muted p-sm">{t('policy.label')}</span>
      <H1 margin="6px 0 1rem 0">{t(title)}</H1>
      <P align="start">{t('policy.sub-title')}</P>
      <PDiv>
        <TransHTML contentKey={intro} />
      </PDiv>
      {content.map((item, i) => (
        <Accordion square key={i}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {t(item.title)}
          </AccordionSummary>
          <PolicyAccordionDetails>
            <TransHTML contentKey={item.content} />
            {item.email && (
              <PolicyMail>
                {t(item.email.textBefore)}
                <a href={`mailto:${item.email.emailID}`}>
                  {item.email.emailID}
                </a>
              </PolicyMail>
            )}
          </PolicyAccordionDetails>
        </Accordion>
      ))}
      {isLastUpdated && (
        <P margin="1.5rem 0" align="start" className="muted p-sm">
          {t('privacy-policy.last_updated', {
            date: PRIVACY_POLICY_LAST_UPDATED_DATE,
          })}
        </P>
      )}
    </PageLayout>
  );
};

const PolicyMail = styled.span`
  a {
    display: inline;
  }
`;
const PDiv = styled.div`
  p {
    line-height: 30px;
  }
`;
const PolicyAccordionDetails = styled(AccordionDetails)`
  p {
    margin-bottom: 12px;
    line-height: 2;
  }
  ul,
  ol {
    margin-bottom: 12px;
  }
  li {
    line-height: 2;
  }
  a {
    color: ${PRIMARY_COLOR};
  }
`;

PolicyLayout.propTypes = {
  title: PropTypes.string,
  intro: PropTypes.string,
  content: PropTypes.array,
  isLastUpdated: PropTypes.bool,
};

export default PolicyLayout;
